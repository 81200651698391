
















import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Prop({ default: '' }) type!: 'find_my_team' | 'find_job' | 'find_hobby_buddy'
}
